export const aiVersionAvailableOptions = [
  // midjourney
  '5.2',
  'niji',
  '6',

  // chat_gpt
  'gpt-4o',
  'gpt-4-vision-preview',
  'gpt-4o-mini',

  // dalle
  'dall-e-3',

  // kandinsky
  '3.1',

  // offline_speech_to_text
  'openai-whisper-large-v3',

  // speech_to_text
  'whisper-1',

  // assistant_gpt
  'gpt-4_assistant',
  'gpt-4o_assistant',
  'gpt-4-1106-preview_assistant',
  'gpt-4-0613_assistant',
  'gpt-4-0125-preview_assistant',
  'gpt-4-turbo_assistant',

  // text_to_speech
  'tts-1',
  'tts-1-hd',

  // yandex_gpt
  'yandex_gpt_lite',
  'yandex_gpt_pro',
  'yandex_gpt_summarization',
];